define("discourse/plugins/discourse-plugin-manager/discourse/helpers/action-link", ["@ember/template", "discourse-common/lib/helpers", "discourse-common/lib/icon-library", "I18n"], function (_template, _helpers, _iconLibrary, _I18n) {
  "use strict";

  (0, _helpers.registerUnbound)("action-link", attrs => {
    let html = `<a href=${attrs.url} class="action-link" target="_blank">`;
    html += (0, _iconLibrary.iconHTML)(attrs.icon);
    html += `<span>${_I18n.default.t(attrs.labelKey)}</span>`;
    html += `</a>`;
    return (0, _template.htmlSafe)(html);
  });
});