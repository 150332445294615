define("discourse/plugins/discourse-plugin-manager/discourse/models/user-plugin", ["exports", "@ember/object", "discourse/lib/ajax", "discourse/lib/ajax-error"], function (_exports, _object, _ajax, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const UserPlugin = _object.default.extend();
  UserPlugin.reopenClass({
    registered(username) {
      return (0, _ajax.ajax)(`/users/${username}/plugins/registered`).catch(_ajaxError.popupAjaxError);
    }
  });
  var _default = _exports.default = UserPlugin;
});