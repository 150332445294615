define("discourse/plugins/discourse-plugin-manager/discourse/helpers/branch-label", ["@ember/template", "discourse-common/lib/helpers"], function (_template, _helpers) {
  "use strict";

  (0, _helpers.registerUnbound)("branch-label", obj => {
    let html = `<span class="branch-label">`;
    if (obj.branchUrl) {
      html += `<a href=${obj.branchUrl} class="branch-link" target="_blank">`;
    }
    html += obj.branch;
    if (obj.branchUrl) {
      html += `</a>`;
    }
    html += "</span>";
    return (0, _template.htmlSafe)(html);
  });
});