define("discourse/plugins/discourse-plugin-manager/discourse/components/plugin-status-button", ["exports", "@ember/component", "@popperjs/core", "@ember/runloop"], function (_exports, _component, _core, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    classNameBindings: [":plugin-status-button", "plugin.name"],
    showLabel: true,
    didInsertElement() {
      $(document).on("click", (0, _runloop.bind)(this, this.documentClick));
    },
    willDestroyElement() {
      $(document).off("click", (0, _runloop.bind)(this, this.documentClick));
    },
    documentClick(event) {
      if (this._state === "destroying") {
        return;
      }
      if (!event.target.closest(`.plugin-status-button.${this.plugin.name}`)) {
        this.set("showStatusDetail", false);
      }
      return true;
    },
    createStatusDetailModal() {
      let container = this.element;
      let modal = this.element.querySelector(".plugin-status-detail");
      this.createModal(container, modal);
    },
    createModal(container, modal) {
      this._popper = (0, _core.createPopper)(container, modal, {
        strategy: "absolute",
        placement: "bottom-start",
        modifiers: [{
          name: "preventOverflow"
        }, {
          name: "offset",
          options: {
            offset: [0, 5]
          }
        }]
      });
    },
    actions: {
      toggleStatusDetail() {
        if (!event.target.closest(`.plugin-status.${this.plugin.name} .log`)) {
          this.toggleProperty("showStatusDetail");
          if (this.showStatusDetail) {
            (0, _runloop.scheduleOnce)("afterRender", this, this.createStatusDetailModal);
          }
        }
      }
    }
  });
});