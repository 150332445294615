define("discourse/plugins/discourse-plugin-manager/discourse/helpers/owner-logo", ["@ember/template", "discourse-common/lib/helpers", "I18n"], function (_template, _helpers, _I18n) {
  "use strict";

  (0, _helpers.registerUnbound)("owner-logo", function (owner) {
    let opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    let classes = `owner-logo ${opts.label ? "has-label" : ""} ${opts.class ? opts.class : ""}`;
    let href = owner.website ? owner.website : owner.url;
    let target = opts.linked ? "_blank" : "";
    let html = `<${opts.linked ? `a href=${href}` : "div"} class="${classes}" target="${target}">`;
    let imgAlt = _I18n.default.t("plugin_manager.plugin.owner.logo", {
      ownerName: owner.name
    });
    html += `<img class="owner-logo-img" src=${owner.avatar_url} alt=${imgAlt}>`;
    if (opts.label) {
      html += `<span class="owner-logo-label">${owner.name}</span>`;
    }
    html += `</${opts.linked ? "a" : "div"}>`;
    return (0, _template.htmlSafe)(html);
  });
});