define("discourse/plugins/discourse-plugin-manager/discourse/routes/admin-plugins-manager", ["exports", "discourse/routes/discourse", "discourse/plugins/discourse-plugin-manager/discourse/models/plugin", "@ember/array"], function (_exports, _discourse, _plugin, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    model() {
      return _plugin.default.list();
    },
    setupController(controller, model) {
      const plugins = (0, _array.A)(model.plugins.map(plugin => _plugin.default.create(plugin)) || []);
      controller.setProperties({
        plugins
      });
    }
  });
});