define("discourse/plugins/discourse-plugin-manager/discourse/components/plugin-manifest", ["exports", "@ember/component", "discourse/plugins/discourse-plugin-manager/discourse/models/plugin", "@ember/array", "discourse-common/utils/decorators"], function (_exports, _component, _plugin, _array, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    classNames: "plugin-manifest",
    page: 0,
    filter: null,
    order: null,
    asc: null,
    canLoadMore: true,
    triggerLoadPlugins() {
      this.setProperties({
        page: 0,
        canLoadMore: true
      });
      this.loadPlugins(false);
    },
    loadPlugins() {
      let addingPage = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      this.set("loading", true);
      const currentNames = this.plugins.map(p => p.name);
      let params = {
        page: this.page,
        order: this.order,
        asc: this.asc,
        filter: this.filter,
        branch: this.discourse.branch
      };
      _plugin.default.list(params).then(result => {
        let plugins = result.plugins;
        if (addingPage) {
          plugins = plugins.filter(p => !currentNames.includes(p.name));
          if (plugins.length === 0) {
            this.set("canLoadMore", false);
            return;
          }
        } else {
          this.set("plugins", (0, _array.A)());
        }
        this.get("plugins").pushObjects(plugins.map(plugin => _plugin.default.create(plugin)));
      }).finally(() => this.set("loading", false));
    },
    actions: {
      loadMore() {
        if (this.canLoadMore) {
          let currentPage = this.get("page");
          this.set("page", currentPage += 1);
          this.loadPlugins(true);
        }
      }
    }
  }, [["method", "triggerLoadPlugins", [(0, _decorators.observes)("filter", "order", "asc")]]]));
});