define("discourse/plugins/discourse-plugin-manager/discourse/models/plugin", ["exports", "discourse-common/utils/decorators", "@ember/string", "discourse/lib/ajax", "discourse/lib/ajax-error", "@ember/object/computed", "discourse/models/rest", "discourse/models/category", "discourse/models/user", "I18n"], function (_exports, _decorators, _string, _ajax, _ajaxError, _computed, _rest, _category, _user, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const statusIcons = {
    unknown: "far-question-circle",
    compatible: "far-check-circle",
    tests_failing: "far-times-circle",
    incompatible: "times-circle"
  };
  const Plugin = _rest.default.extend(dt7948.p({
    statusIcon(status) {
      return status ? statusIcons[status] : "";
    },
    statusTitle(status) {
      return status ? _I18n.default.t(`plugin_manager.plugin.status.${status}.title`) : "";
    },
    statusClass(status) {
      return status ? (0, _string.dasherize)(status) : "";
    },
    ownerClass(owner) {
      return owner ? "" : "no-owner";
    },
    testLink(name) {
      return `/c/${name}`;
    },
    hasContactEmails: (0, _computed.notEmpty)("contactEmails"),
    contactEmails(emails) {
      return emails ? emails.split(",") : [];
    },
    authorList(authors) {
      return authors ? authors.split(",") : [];
    },
    branchUrl: (0, _computed.readOnly)("branch_url"),
    branch: (0, _computed.readOnly)("status.branch"),
    documentationCategory(categoryId) {
      return _category.default.findById(categoryId);
    },
    supportCategory(categoryId) {
      return _category.default.findById(categoryId);
    },
    reload(discourseBranch) {
      Plugin.find(this.name, discourseBranch).then(result => {
        this.setProperties(result.plugin);
      });
    },
    maintainerUser(user) {
      if (user) {
        return _user.default.create(user);
      } else {
        return null;
      }
    }
  }, [["method", "statusIcon", [(0, _decorators.default)("status.status")]], ["method", "statusTitle", [(0, _decorators.default)("status.status")]], ["method", "statusClass", [(0, _decorators.default)("status.status")]], ["method", "ownerClass", [(0, _decorators.default)("owner")]], ["method", "testLink", [(0, _decorators.default)("name")]], ["method", "contactEmails", [(0, _decorators.default)("contact_emails")]], ["method", "authorList", [(0, _decorators.default)("authors")]], ["method", "documentationCategory", [(0, _decorators.default)("documentation_category_id")]], ["method", "supportCategory", [(0, _decorators.default)("support_category_id")]], ["method", "maintainerUser", [(0, _decorators.default)("maintainer_user")]]]));
  Plugin.reopenClass({
    discourse() {
      return (0, _ajax.ajax)("/plugin-manager/discourse");
    },
    list(data) {
      return (0, _ajax.ajax)("/plugin-manager/plugin", {
        type: "GET",
        data
      }).catch(_ajaxError.popupAjaxError);
    },
    find(pluginName, branch) {
      return (0, _ajax.ajax)(`/plugin-manager/plugin/${pluginName}`, {
        type: "GET",
        data: {
          branch
        }
      }).catch(_ajaxError.popupAjaxError);
    },
    categoryPlugin(categoryId) {
      let data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      return (0, _ajax.ajax)(`/plugin-manager/plugin/category/${categoryId}`, {
        type: "GET",
        data
      }).catch(_ajaxError.popupAjaxError);
    },
    retrieve(data) {
      return (0, _ajax.ajax)("/plugin-manager/plugin/retrieve", {
        type: "GET",
        data
      }).catch(_ajaxError.popupAjaxError);
    },
    save(plugin) {
      return (0, _ajax.ajax)(`/plugin-manager/plugin/${plugin.name}`, {
        type: "PUT",
        data: {
          plugin
        }
      }).catch(_ajaxError.popupAjaxError);
    },
    destroy(pluginName) {
      return (0, _ajax.ajax)(`/plugin-manager/plugin/${pluginName}`, {
        type: "DELETE"
      }).catch(_ajaxError.popupAjaxError);
    }
  });
  var _default = _exports.default = Plugin;
});