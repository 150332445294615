define("discourse/plugins/discourse-plugin-manager/discourse/controllers/admin-plugins-manager", ["exports", "@ember/controller", "discourse/plugins/discourse-plugin-manager/discourse/models/plugin", "discourse/lib/show-modal"], function (_exports, _controller, _plugin, _showModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend({
    removePlugin(pluginName) {
      const plugins = this.get("plugins");
      const removePlugin = plugins.findBy("name", pluginName);
      plugins.removeObject(removePlugin);
    },
    addPlugin(plugin) {
      this.get("plugins").unshiftObject(plugin);
    },
    actions: {
      addPlugin(plugin) {
        let model = plugin || _plugin.default.create({
          new: true
        });
        let controller = (0, _showModal.default)("plugin-manager-plugin-editor", {
          model
        });
        controller.setProperties({
          afterSave: addedPlugin => this.addPlugin(addedPlugin)
        });
        controller.setupEvents();
      },
      removePlugin(plugin) {
        this.set("destroying", true);
        _plugin.default.destroy(plugin.name).then(result => {
          if (result.success) {
            this.removePlugin(result.plugin_name);
          }
          this.set("destroying", false);
        });
      },
      editPlugin(plugin) {
        let originalPlugin = plugin;
        let controller = (0, _showModal.default)("plugin-manager-plugin-editor", {
          model: plugin
        });
        controller.setProperties({
          afterSave: savedPlugin => {
            this.removePlugin(originalPlugin.name);
            this.addPlugin(savedPlugin);
          },
          afterDestroy: removedPlugin => this.removePlugin(removedPlugin)
        });
        controller.setupEvents();
      }
    }
  });
});