define("discourse/plugins/discourse-plugin-manager/discourse/components/owner-detail", ["exports", "@ember/component", "discourse-common/utils/decorators", "@ember/object/computed", "I18n"], function (_exports, _component, _decorators, _computed, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    classNames: ["owner-detail", "plugin-manager-detail"],
    hasMetadata: (0, _computed.alias)("owner.description"),
    hasActions: (0, _computed.or)("owner.website", "owner.email"),
    typeIcon(type) {
      return {
        user: "user",
        organization: "building"
      }[type];
    },
    typeTitle(type) {
      return _I18n.default.t(`plugin_manager.plugin.owner.type.${type}`);
    },
    mailtoUrl(email) {
      return `mailto:${email}`;
    },
    click(event) {
      event.stopPropagation();
    }
  }, [["method", "typeIcon", [(0, _decorators.default)("owner.type")]], ["method", "typeTitle", [(0, _decorators.default)("owner.type")]], ["method", "mailtoUrl", [(0, _decorators.default)("owner.email")]]]));
});