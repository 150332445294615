define("discourse/plugins/discourse-plugin-manager/discourse/components/plugin-detail", ["exports", "@ember/component", "discourse-common/utils/decorators"], function (_exports, _component, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    classNames: ["plugin-detail", "plugin-manager-detail"],
    documentationCategory(pluginCategoryId, categoryId) {
      return !categoryId || pluginCategoryId === categoryId;
    },
    supportCategory(pluginCategoryId, categoryId) {
      return pluginCategoryId && pluginCategoryId === categoryId;
    },
    aboutClass(manifest) {
      return manifest ? "full-width" : "";
    },
    didInsertElement() {
      this._super(...arguments);
      if (this.category) {
        document.body.classList.add("plugin-category");
      }
    },
    willDestroyElement() {
      this._super(...arguments);
      if (this.category) {
        document.body.classList.remove("plugin-category");
      }
    },
    click(event) {
      event.stopPropagation();
    }
  }, [["method", "documentationCategory", [(0, _decorators.default)("plugin.documentationCategory.id", "category.id")]], ["method", "supportCategory", [(0, _decorators.default)("plugin.supportCategory.id", "category.id")]], ["method", "aboutClass", [(0, _decorators.default)("manifest")]]]));
});