define("discourse/plugins/discourse-plugin-manager/discourse/connectors/category-heading/plugin-manager-category-plugin", ["exports", "discourse/plugins/discourse-plugin-manager/discourse/models/plugin", "discourse/plugins/discourse-plugin-manager/discourse/models/discourse"], function (_exports, _plugin, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    setupComponent(attrs) {
      let category = attrs.category;
      function observerCallback() {
        if (this._state === "destroying") {
          return;
        }
        _plugin.default.categoryPlugin(category.id, {
          branch: category.discourse.branch
        }).then(result => {
          category.setProperties({
            discourse: _discourse.default.create({
              branch: result.branch
            }),
            plugin: _plugin.default.create(result.plugin)
          });
          category.discourse.addObserver("branch", observerCallback);
        });
      }
      if (category.discourse) {
        category.discourse.addObserver("branch", observerCallback);
      }
    }
  };
});