define("discourse/plugins/discourse-plugin-manager/discourse/initializers/plugin-manager-initializer", ["exports", "discourse/plugins/discourse-plugin-manager/discourse/models/plugin", "discourse/plugins/discourse-plugin-manager/discourse/models/discourse", "discourse/lib/plugin-api", "discourse-common/utils/decorators"], function (_exports, _plugin, _discourse, _pluginApi, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "plugin-manager",
    initialize(container) {
      const messageBus = container.lookup("service:message-bus");
      messageBus.subscribe("/plugin-manager/status-updated", function (pluginName) {
        const pluginsController = container.lookup("controller:plugins");
        const plugin = pluginsController.plugins.findBy("name", pluginName);
        const discourse = pluginsController.discourse;
        if (plugin && discourse) {
          plugin.reload(discourse.branch);
        }
      });
      (0, _pluginApi.withPluginApi)("1.6.0", api => {
        api.modifyClass("route:discovery.category", {
          pluginId: "plugin-manager",
          afterModel(model) {
            return _plugin.default.categoryPlugin(model.category.id).then(result => {
              this.setProperties(result);
            });
          },
          setupController(_, model) {
            this._super(...arguments);
            if (this.plugin) {
              const branch = this.branch;
              const discourse = _discourse.default.create({
                branch
              });
              const plugin = _plugin.default.create(this.plugin);
              model.category.setProperties({
                discourse,
                plugin
              });
            }
          }
        });
        api.modifyClass("controller:navigation/category", dt7948.p({
          pluginId: "plugin-manager",
          displayCategory(category, parentCategory) {
            return parentCategory || category;
          },
          pluginCategories(categories) {
            return categories.filter(c => c.for_plugin);
          }
        }, [["method", "displayCategory", [(0, _decorators.default)("category", "category.parentCategory")]], ["method", "pluginCategories", [(0, _decorators.default)("site.categories")]]]));
      });
    }
  };
});