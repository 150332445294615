define("discourse/plugins/discourse-plugin-manager/discourse/components/plugin-status-detail", ["exports", "@ember/component", "discourse-common/utils/decorators", "@ember/object/computed", "I18n"], function (_exports, _component, _decorators, _computed, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    classNames: ["plugin-status-detail", "plugin-manager-detail"],
    hasLog: (0, _computed.notEmpty)("plugin.log"),
    detailDescription(pluginName, pluginStatus, pluginLastStatusAt, pluginStatusChangedAt, pluginBranch, discourseBranch) {
      return _I18n.default.t(`plugin_manager.plugin.status.${pluginStatus}.description`, {
        plugin_name: pluginName,
        plugin_branch: pluginBranch,
        discourse_branch: discourseBranch,
        plugin_last_status_at: moment(pluginLastStatusAt).fromNow(),
        plugin_status_changed_at: moment(pluginStatusChangedAt).fromNow()
      });
    },
    detailTitle(pluginStatus) {
      return _I18n.default.t(`plugin_manager.plugin.status.${pluginStatus}.detail_title`);
    },
    actions: {
      toggleLog() {
        this.toggleProperty("showLog");
      }
    },
    click(event) {
      event.stopPropagation();
    }
  }, [["method", "detailDescription", [(0, _decorators.default)("plugin.name", "plugin.status.status", "plugin.status.last_status_at", "plugin.status.status_changed_at", "plugin.status.branch", "discourse.branch")]], ["method", "detailTitle", [(0, _decorators.default)("plugin.status.status")]]]));
});