define("discourse/plugins/discourse-plugin-manager/discourse/models/discourse", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Discourse = _object.default.extend({
    url: "https://github.com/discourse/discourse",
    branch: "tests-passed"
  });
  var _default = _exports.default = Discourse;
});