define("discourse/plugins/discourse-plugin-manager/discourse/controllers/plugin-manager-plugin-editor", ["exports", "@ember/controller", "discourse-common/utils/decorators", "discourse/plugins/discourse-plugin-manager/discourse/models/plugin", "@ember/object/computed", "discourse/mixins/modal-functionality", "@ember/runloop"], function (_exports, _controller, _decorators, _plugin, _computed, _modalFunctionality, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend(_modalFunctionality.default, dt7948.p({
    readOnlyStatus: (0, _computed.equal)("model.status", "tests_failing"),
    showPlugin: (0, _computed.or)("model.name", "retrieving"),
    canAdd: false,
    isNew: (0, _computed.alias)("model.new"),
    canRetrieve: (0, _computed.alias)("isNew"),
    urlDisabled: (0, _computed.not)("canRetrieve"),
    retrieveDisabled: (0, _computed.empty)("model.url"),
    discourseBranches: ["tests-passed", "stable"],
    addPluginDisabled(retrieved, retrieving) {
      return !retrieved || retrieving;
    },
    modalTitle(isNew) {
      return `admin.plugin_manager.plugin.${isNew ? "add" : "edit"}`;
    },
    setupEvents() {
      (0, _runloop.schedule)("afterRender", () => {
        const element = document.querySelector(".plugin-url");
        element.addEventListener("keydown", this.keyDown);
      });
    },
    onClose() {
      const element = document.querySelector(".plugin-url");
      element.removeEventListener("keydown", this.keyDown);
      this.set("model", null);
    },
    keyDown(event) {
      if (event.key === "Enter" && this.canRetrieve && !this.retrieveDisabled) {
        this.send("retrieve");
      }
    },
    actions: {
      retrieve() {
        this.clearFlash();
        this.set("retrieving", true);
        const data = {
          url: this.model.url
        };
        _plugin.default.retrieve(data).then(result => {
          if (result.success) {
            this.setProperties({
              model: _plugin.default.create(result.plugin),
              retrieved: true
            });
          } else {
            this.flash(result.error, "error");
          }
          this.set("retrieving", false);
        });
      },
      save() {
        this.set("saving", true);
        const model = this.model;
        const attrs = {
          url: model.url,
          name: model.name,
          authors: model.authors,
          about: model.about,
          contact_emails: model.contact_emails,
          maintainers: model.maintainers,
          test_host: model.test_host
        };
        _plugin.default.save(attrs).then(result => {
          if (result.success) {
            this.afterSave(_plugin.default.create(result.plugin));
          }
          this.set("saving", false);
          this.send("closeModal");
        });
      }
    }
  }, [["method", "addPluginDisabled", [(0, _decorators.default)("retrieved", "retrieving")]], ["method", "modalTitle", [(0, _decorators.default)("isNew")]], ["method", "keyDown", [_decorators.bind]]]));
});