define("discourse/plugins/discourse-plugin-manager/discourse/routes/user-plugins-registered", ["exports", "@ember/routing/route", "discourse/plugins/discourse-plugin-manager/discourse/models/user-plugin"], function (_exports, _route, _userPlugin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _route.default.extend({
    model() {
      const user = this.modelFor("user");
      return _userPlugin.default.registered(user.username);
    }
  });
});