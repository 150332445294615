define("discourse/plugins/discourse-plugin-manager/discourse/components/plugin-status", ["exports", "@ember/component", "@ember/runloop", "@popperjs/core", "discourse-common/utils/decorators", "discourse/models/category", "discourse/lib/url"], function (_exports, _component, _runloop, _core, _decorators, _category, _url) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    tagName: "tr",
    classNameBindings: [":plugin-status", "plugin.statusClass", "plugin.name", "plugin.ownerClass"],
    showPluginDetail: false,
    showStatusDetail: false,
    didInsertElement() {
      $(document).on("click", (0, _runloop.bind)(this, this.documentClick));
    },
    willDestroyElement() {
      $(document).off("click", (0, _runloop.bind)(this, this.documentClick));
    },
    click(e) {
      if (!e.target.closest(".btn-plugin, .btn-owner-logo, .status-badge")) {
        _url.default.routeTo(this.plugin.supportCategory.url);
      }
    },
    documentClick(event) {
      if (this._state === "destroying") {
        return;
      }
      if (!event.target.closest(`.plugin-status.${this.plugin.name} .name-container`)) {
        this.set("showPluginDetail", false);
      }
      if (!event.target.closest(`.plugin-status.${this.plugin.name} .owner-container`)) {
        this.set("showOwnerDetail", false);
      }
      return true;
    },
    createPluginDetailModal() {
      let container = this.element.querySelector(".name-container");
      let modal = this.element.querySelector(".plugin-detail");
      this.createModal(container, modal);
    },
    createOwnerDetailModal() {
      let container = this.element.querySelector(".owner-container");
      let modal = this.element.querySelector(".owner-detail");
      this.createModal(container, modal);
    },
    createModal(container, modal) {
      this._popper = (0, _core.createPopper)(container, modal, {
        strategy: "absolute",
        placement: "bottom-start",
        modifiers: [{
          name: "preventOverflow"
        }, {
          name: "offset",
          options: {
            offset: [0, 5]
          }
        }]
      });
    },
    supportLink(contactEmails, owner) {
      if (owner && owner.name && owner.name.toLowerCase() === "pavilion") {
        return `https://thepavilion.io`;
      }
      return `mailto:${contactEmails[0]}`;
    },
    pluginDocumentationCategory(categoryId) {
      return _category.default.findById(categoryId);
    },
    pluginSupportCategory(categoryId) {
      return _category.default.findById(categoryId);
    },
    actions: {
      togglePluginDetail() {
        this.toggleProperty("showPluginDetail");
        if (this.showPluginDetail) {
          (0, _runloop.scheduleOnce)("afterRender", this, this.createPluginDetailModal);
        }
      },
      toggleOwnerDetail() {
        this.toggleProperty("showOwnerDetail");
        if (this.showOwnerDetail) {
          (0, _runloop.scheduleOnce)("afterRender", this, this.createOwnerDetailModal);
        }
      }
    }
  }, [["method", "supportLink", [(0, _decorators.default)("plugin.contactEmails", "plugin.owner")]], ["method", "pluginDocumentationCategory", [(0, _decorators.default)("plugin.documentation_category_id")]], ["method", "pluginSupportCategory", [(0, _decorators.default)("plugin.support_category_id")]]]));
});