define("discourse/plugins/discourse-plugin-manager/discourse/routes/plugins", ["exports", "discourse/routes/discourse", "discourse/plugins/discourse-plugin-manager/discourse/models/discourse", "discourse/plugins/discourse-plugin-manager/discourse/models/plugin", "@ember/array"], function (_exports, _discourse, _discourse2, _plugin, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    queryParams: {
      branch: {
        refreshModel: true
      }
    },
    model(params) {
      return _plugin.default.list({
        branch: params.branch
      });
    },
    setupController(controller, model) {
      this._super(...arguments);
      const discourse = _discourse2.default.create({
        branch: model.branch
      });
      const plugins = (0, _array.A)(model.plugins.map(plugin => _plugin.default.create(plugin)));
      controller.setProperties({
        discourse,
        plugins
      });
    }
  });
});