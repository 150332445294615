define("discourse/plugins/discourse-plugin-manager/discourse/components/plugin-actions", ["exports", "@ember/component", "discourse-common/utils/decorators", "@ember/service"], function (_exports, _component, _decorators, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    classNames: ["plugin-actions"],
    router: (0, _service.inject)(),
    issueLabel(mobileView, textOnly) {
      return mobileView || textOnly ? null : "plugin_manager.plugin.issues.label";
    },
    documentationLabel(mobileView, textOnly) {
      return mobileView || textOnly ? null : "plugin_manager.plugin.documentation.label";
    },
    issuesClass(currentURL) {
      return currentURL.includes("/issues/") ? "btn-primary" : "";
    },
    documentationClass(currentURL) {
      return currentURL.includes("/documentation/") ? "btn-primary" : "";
    }
  }, [["method", "issueLabel", [(0, _decorators.default)("site.mobileView", "textOnly")]], ["method", "documentationLabel", [(0, _decorators.default)("site.mobileView", "textOnly")]], ["method", "issuesClass", [(0, _decorators.default)("router.currentURL")]], ["method", "documentationClass", [(0, _decorators.default)("router.currentURL")]]]));
});