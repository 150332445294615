define("discourse/plugins/discourse-plugin-manager/select-kit/components/plugin-tag-chooser", ["exports", "select-kit/components/tag-chooser", "discourse-common/lib/helpers"], function (_exports, _tagChooser, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _tagChooser.default.extend({
    classNames: "plugin-tag-chooser",
    selectKitOptions: {
      allowAny: false,
      none: "plugin_manager.plugin.select_tags"
    },
    search(query) {
      const selectedTags = (0, _helpers.makeArray)(this.tags).filter(Boolean);
      let pluginTags = this.site.plugin_tags;
      if (selectedTags) {
        pluginTags = pluginTags.filter(tag => !selectedTags.includes(tag));
      }
      if (query) {
        pluginTags = pluginTags.filter(tag => tag.includes(query));
      }
      return pluginTags.map(tag => ({
        id: tag,
        name: tag
      }));
    }
  });
});