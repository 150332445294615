define("discourse/plugins/discourse-plugin-manager/discourse/components/discourse-status", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    classNames: ["discourse-status"],
    branches: ["tests-passed", "stable"].map(b => ({
      id: b,
      name: b
    }))
  });
});