define("discourse/plugins/discourse-plugin-manager/discourse/controllers/plugins", ["exports", "@ember/controller", "discourse-common/utils/decorators"], function (_exports, _controller, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend(dt7948.p({
    queryParams: ["branch"],
    setBranch() {
      this.set("branch", this.discourse.branch);
    }
  }, [["method", "setBranch", [(0, _decorators.observes)("discourse.branch")]]]));
});